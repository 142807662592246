import { SanityDocument, groq } from "next-sanity";
import { Slug } from "sanity";
import { type PortableTextBlock } from "@portabletext/types";

import { getClient } from "../utils";

export interface IPromotion extends SanityDocument {
  _type: "promotion";
  slug: Slug;
  title: string;
  week: number;
  year: number;
  campaignId: string;
  cutoffDate: string;
  showDot?: boolean;
  promotionIdentity: {
    indefiniteName: string;
    icon: string;
  };
  topSection: PortableTextBlock[];
  mealboxSection: PortableTextBlock[];
  grocerySection: PortableTextBlock[];
  faqSection: PortableTextBlock[];
  soldoutTopSection: PortableTextBlock[];
  soldoutBottomSection: PortableTextBlock[];
}

export const promotionQuery = groq`*[_type == "promotion" && slug.current == $slug][0]`;

export const promotionByCampaignIdQuery = groq`*[_type == "promotion" && campaignId == $campaignId][0]`;

export const promotionsQuery = groq`*[_type == "promotion"]`;

export const getPromotionData = async (
  slug: string,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<IPromotion | null>(promotionQuery, { slug });
};

export const getPromotionsData = async () => {
  const client = getClient();

  return client.fetch<IPromotion[]>(promotionsQuery);
};

export const getFirstPromotionData = async () => {
  const client = getClient();

  return client.fetch<IPromotion | null>(promotionsQuery + `[0]`);
};
