import { useLiveQuery } from "next-sanity/preview";
import {
  type IRecipesCategory,
  recipesCategoriesQuery,
  recipesCategoryQuery,
} from "../queries";

export const useRecipesCategoryData = (data?: IRecipesCategory) => {
  return useLiveQuery(data || null, recipesCategoryQuery, {
    slug: data?.slug.current || "",
  });
};

export const useRecipesCategoriesData = (data?: IRecipesCategory[]) => {
  return useLiveQuery(data || null, recipesCategoriesQuery);
};
