import { useLiveQuery } from "next-sanity/preview";
import { type IMealbox, mealboxQuery, mealboxesQuery } from "../queries";

export const useMealboxData = (data?: IMealbox) => {
  return useLiveQuery(data || null, mealboxQuery, {
    slug: data?.slug.current || "",
  });
};

export const useMealboxesData = (data?: IMealbox[]) => {
  return useLiveQuery(data || null, mealboxesQuery);
};
